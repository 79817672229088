 <template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">{{ $t('categories') }} <span class="text-md"> - {{ $t('articles') }}</span> </h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item active"><router-link :to="{ name: 'dashReports'}">{{ $t('dashboard') }}</router-link></li>
                     <li class="breadcrumb-item">{{ $t('categories') }}</li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div class="container-fluid">
            <!-- /.row -->
            <div class="row">
               <div class="col-12">
                  <div class="card">
                     <div class="card-header">
                        <router-link
                           :to="{ name: 'categoriesCreate' }"
                           class="btn btn-success"
                        >
                           <i class="fas fa-plus-circle"></i> {{ $t('create_category') }}
                        </router-link>
                        <div class="card-tools">
                           <div class="input-group input-group-sm pt-2" style="width: 350px;">
                           <input
                              type="text"
                              name="table_search"
                              class="form-control float-right"
                              placeholder="Buscar"
                              v-model="searchQuery"
                              @input="handleDelayedSearch"
                           >
                           <div class="input-group-append">
                              <button type="submit" class="btn btn-default">
                              <i class="fas fa-search"></i>
                              </button>
                           </div>
                        </div>
                        </div>
                     </div>
                     <!-- /.card-header -->

                     <div v-if="loadingCategories" class="d-flex justify-content-center">{{ $t('loading') }}</div>

                     <div v-else class="card-body table-responsive p-0">
                        <table class="table table-hover text-nowrap">
                           <thead>
                           <tr>
                              <th>{{ $t('name') }}</th>
                              <th>{{ $t('created_at') }}</th>
                              <th>{{ $t('subcategories') }}</th>
                              <th>{{ $t('action') }}</th>
                           </tr>
                           </thead>
                           <tbody>
                           <tr v-for="(category) in categories" :key="category.uuid">
                              <td>{{ category.name }}</td>
                              <td>{{ category.createdAt }}</td>
                              <td style="text-overflow: ellipsis">{{ subcategories(category.subcategories) }}</td>
                              <td>
                                 <div class="row">
                                    <router-link
                                       :to="{ name: 'categoriesEdit', params: { uuid: category.uuid } }"
                                       class="btn btn-sm btn-outline-primary text-bold"
                                    >
                                       <i class="pt-1 fas fa-pencil-alt"></i>
                                       {{ $t('edit') }}
                                    </router-link>

                                    <button
                                       class="ml-3 btn btn-sm btn-outline-danger text-bold"
                                       @click="showConfirmationModal(category)"
                                    >
                                       <i class="pt-1 fas fa-trash-alt"></i>
                                       {{ $t('exclude') }}
                                    </button>
                                 </div>
                              </td>
                              <ConfirmationModal
                                 ref="modalConfirmation"
                                 :modalId="modalConfirmationActionId + category.uuid"
                                 @actionConfirmed="deleteCategory"
                                 @actionCancelled="hideConfirmationModal"
                              />
                           </tr>
                           </tbody>
                        </table>
                     </div>
                     <!-- /.card-body -->

                     <div class="card-footer clearfix">
                        <pagination
                           v-if="!isSearching"
                           :totalPages="totalPages"
                           :currentPage="currentPage"
                           :isFirstPage="isFirstPage"
                           :isLastPage="isLastPage"
                           :adjacentPages="adjacentPages"
                           :tokenPages="tokenPages"
                           :isLoading="isLoadingPagination"
                           @page-selected="handlePageSelected"
                        />
                     </div>
                     <!-- /.card-footer -->

                  </div>
                  <!-- /.card -->
               </div>
            </div>
            <!-- /.row -->
         </div>
      </section>
      <!-- /.content -->
   </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ConfirmationModal from "../../../components/ConfirmationModal.vue";
import Pagination from "../../../components/Pagination.vue";
import { useToast } from "vue-toastification";

export default {
   name: "Categories",
   setup() {
      const toast = useToast()
      return { toast }
   },
   components: {
      ConfirmationModal,
      Pagination
   },
   data () {
      return {
         categorySelected: null,
         modalConfirmationActionId: "modal-confirmation-action-categories",
         searchQuery: "",
         searchTimeout: null,
         adjacentPagesCount: 2,
         isLoadingPagination: false,
         isSearching: false
      }
   },
   async created() {
      this.isLoadingPagination = true;
      this.setCurrentPage(1);

      await this.getAllPaginatedCategories();

      try {
         if (this.tokenPages.length === 0)
            await this.getInfoPaginationCategories();
      }
      finally {
         this.isLoadingPagination = false;
      }
   },
   computed: {
      ...mapState({
         categories: state => state.category.categories.data,
         loadingCategories: state => state.category.categories.loading,
         currentPage: state => state.category.categories.pagination.currentPage,
         tokenPages: state => state.category.categories.pagination.tokenPages,
         totalPages: state => state.category.categories.pagination.totalPages
      }),
      adjacentPages() {
         const currentIndex = this.tokenPages.findIndex((page) => page.page === this.currentPage);

         const startIdx = Math.max(0, currentIndex - this.adjacentPagesCount);
         const endIdx = Math.min(this.tokenPages.length - 1, currentIndex + this.adjacentPagesCount);

         const pages = this.tokenPages.slice(startIdx, endIdx + 1);

         return pages;
      },
      isFirstPage() {
         return this.currentPage === 1;
      },
      isLastPage() {
         return this.currentPage === this.totalPages;
      }
   },
   methods: {
      ...mapActions(['getAllPaginatedCategories', 'getInfoPaginationCategories', 'deleteSelectedCategory', 'searchCategories']),
      ...mapMutations({
         setCurrentPage: 'SET_CATEGORY_CURRENT_PAGE'
      }),
      async handlePageSelected (action) {
         let pageIndex;

         if (action === 'first' && !this.isFirstPage) {
            pageIndex = 1;
         } else if (action === 'prev' && !this.isFirstPage) {
            this.setCurrentPage(this.currentPage - 1);
            pageIndex = this.currentPage;
         } else if (action === 'next' && !this.isLastPage) {
            this.setCurrentPage(this.currentPage + 1);
            pageIndex = this.currentPage;
         } else if (action === 'last' && !this.isLastPage) {
            pageIndex = this.totalPages;
         } else if (typeof action === 'number') {
            pageIndex = action;
         }

         if (pageIndex) {
            const targetPage = this.tokenPages.find((page) => page.page === pageIndex);

            if (targetPage) {
               this.setCurrentPage(targetPage.page);
               await this.getAllPaginatedCategories({ pageToken: targetPage.token });
            }
         }
      },
      subcategories (subCat) {
         const arrayOfSubCats = subCat.map(item => `${item.name}`);

         return arrayOfSubCats.join(', ');
      },
      showConfirmationModal(item) {
         this.categorySelected = item
         $(`#${this.modalConfirmationActionId}${this.categorySelected.uuid}`).modal('show')
      },
      hideConfirmationModal() {
         $(`#${this.modalConfirmationActionId}${this.categorySelected.uuid}`).modal('hide')
         $('.modal-backdrop').remove();
         this.categorySelected = null
      },
      async deleteCategory() {
         try {
            this.$refs.modalConfirmation.loading = true
            await this.deleteSelectedCategory(this.categorySelected.uuid);

            this.hideConfirmationModal();
            this.toast.success('O item foi excluído');
            await this.getInfoPagination();
         } catch (error) {
            const { status } = error.response;

            this.hideConfirmationModal()

            if (status == 412)
               this.toast.error(this.$t('category_deletion_fail_because_is_foreign_key'));
            else
               this.toast.error(this.$t('an_error_occurred'));
         }
      },
      async handleDelayedSearch() {
         clearTimeout(this.searchTimeout);

         this.searchTimeout = setTimeout(async () => {
            if (this.searchQuery.length > 0) {
               this.isSearching = true;
               await this.searchCategories(this.searchQuery)
            } else {
               this.isSearching = false;
               await this.getAllPaginatedCategories();
            }
         }, 1000);
      }
   }
}
</script>

<style scoped>
</style>
